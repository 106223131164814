import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Modal, Button, Form, Alert } from 'react-bootstrap';
import manufListHook from '@netcom-network-v2/shared/src/hooks/manufListHook';
import { getFlexBasis } from '../utils/getFlexBasis';
import PartDetailsModal from './partDetailsModal';
import usePartSearch from '../hooks/usePartSearch';

function PartSearch() {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [partNumber, setPartNumber] = useState('');
  const [descriptSearch, setDescriptSearch] = useState('');
  const [condition, setCondition] = useState('All'); // State for condition
  const [manufacturer, setManufacturer] = useState('All'); // State for condition
  const [comments, setComments] = useState(''); // State for condition
  const { processedParts, error, searchParts } = usePartSearch(
    baseUrl,
    navigate
  );
  const hasSearchedRef = useRef(false);

  const { manufList } = manufListHook(baseUrl);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();

  // Modals
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);

  // State for part details modal
  const [currentPartImages, setCurrentPartImages] = useState([]);
  const [focusedField, setFocusedField] = useState(null); // Track which field is focused
  const [currentPart, setCurrentPart] = useState('');

  // State for sending messages
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sending, setSending] = useState(false);
  const [sendError, setSendError] = useState('');
  const [partMessages, setPartMessages] = useState({});
  const [globalErrorMessage, setGlobalErrorMessage] = useState('');

  // State for partfinder users
  const [partfinderUserApproved, setPartfinderUserApproved] = useState('no');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (hasSearchedRef.current) return;
    const queryParams = new URLSearchParams(location.search);
    const queryPartNumber = queryParams.get('partNumber') || '';
    const queryDescription = queryParams.get('description') || '';

    if (queryPartNumber || queryDescription) {
      setPartNumber(queryPartNumber);
      setDescriptSearch(queryDescription);
      setCondition('All'); // Reset to default or based on additional parameters
      setManufacturer('All'); // Reset to default or based on additional parameters
      setComments(''); // Reset to default or based on additional parameters
      setPartfinderUserApproved('no');
      // Perform the search
      searchParts(queryPartNumber, 'All', 'All', queryDescription, '', 'no');

      // Clear the query parameters from the URL to prevent re-triggering the search
      navigate(location.pathname, { replace: true });
      hasSearchedRef.current = true;
    }
    // Dependencies array includes location.search, searchParts, and navigate
  }, [location.search, searchParts, navigate]);

  const openDetailsModal = part => {
    setCurrentPartImages(part.pictures);
    setCurrentPart(part);
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const openMessageModal = part => {
    setCurrentPart(part);
    setSubject(`Question about Part #${part.partnum || part.partNumber || ''}`);
    setBody(''); // Clear the message body
    setSendError(''); // Clear any previous errors
    setGlobalErrorMessage('');
    setShowMessageModal(true);
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
    setGlobalErrorMessage('');
    setSendError('');
  };

  const handleInputChange = e => {
    const formattedInput = e.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    setPartNumber(formattedInput);
  };

  const handleDescriptChange = e => {
    setDescriptSearch(e.target.value);
  };

  const handleConditionChange = e => {
    setCondition(e.target.value);
  };

  const handleManufacturerChange = e => {
    setManufacturer(e.target.value);
  };

  const handleCommentChange = e => {
    setComments(e.target.value);
  };

  const handleSearch = async () => {
    await searchParts(
      partNumber,
      condition,
      manufacturer,
      descriptSearch,
      comments,
      partfinderUserApproved
    );
    const partsFound = processedParts.parts || [];

    if (partsFound.length > 0) {
      // Parts were found, reset the input fields
      setPartNumber('');
      setDescriptSearch('');
      setCondition('All');
      setManufacturer('All');
      setComments('');
    }

    setGlobalErrorMessage(''); // Clear global error message
    setPartMessages({}); // Clear previous success messages
  };

  const handleSendMessage = async () => {
    if (!subject || !body) {
      setSendError('Subject and body are required.');
      return;
    }

    setSending(true);
    setSendError('');
    setGlobalErrorMessage(''); // Clear any previous global errors

    const messageData = {
      recipientCompanyId: currentPart.companyid,
      subject,
      body,
      relatedPartId: currentPart.partSearchResultId,
      messageType: 'part'
    };

    try {
      const response = await fetch(`${baseUrl}/api/message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(messageData)
      });

      const result = await response.json();
      if (response.ok) {
        // Update partMessages with a success message for this part
        setPartMessages(prevMessages => ({
          ...prevMessages,
          [currentPart.id]: `Message successfully sent to ${currentPart.companyname || currentPart.companyName}.`
        }));
        closeMessageModal();
      } else {
        // Close the modal and set the global error message
        closeMessageModal();
        setGlobalErrorMessage(result.message || 'Failed to send message.');
      }
    } catch (err) {
      // Close the modal and set the global error message
      closeMessageModal();
      setGlobalErrorMessage('An error occurred while sending the message.');
    } finally {
      setSending(false);
    }
  };

  processedParts.forEach(part => {
    if (part.pics?.length > 0) {
      // Only process if pics is not empty
      let pictures; // Declare pictures without initializing it

      if (part.pics.includes(';')) {
        pictures = part.pics
          .split('; ')
          .map((url, idx) => ({ url: url.trim(), picId: idx }));
      } else {
        pictures = [
          {
            url: part.pics.trim(),
            picId: 0 // picId is 0 since there's only one picture
          }
        ];
      }
      // Create a copy of the part object and assign the pictures array to it
      Object.assign(part, { pictures });
    }
  });

  useEffect(() => {
    if (partfinderUserApproved === 'yes') {
      handleSearch();
    }
  }, [partfinderUserApproved]);

  const handleRedirectToPartFinder = () => {
    window.location.href = 'https://www.thepartfinder.com';
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {focusedField && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10 // Ensure overlay is just below the input fields
          }}
          onClick={() => setFocusedField(null)} // Click outside any field to unfocus
          onKeyDown={e => {
            if (e.key === 'Escape') {
              setFocusedField(null); // Handle escape key to close overlay
            }
          }}
          role="button" // Assign role to indicate the div is interactive
          tabIndex={0} // Make it focusable
          aria-label="Close overlay" // Provide an accessible name
        />
      )}
      <div
        style={{
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          margin: '20px',
          padding: '20px',
          borderRadius: '8px',
          backgroundColor: 'lightBlue'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            justifyContent: 'center',
            marginBottom: '10px',
            marginTop: '10px',
            width: '100%'
          }}
        >
          <input
            id="partNumber"
            type="text"
            value={partNumber}
            onChange={handleInputChange}
            placeholder="Enter Part Number"
            onFocus={() => setFocusedField('partNumber')}
            onBlur={() => setFocusedField(null)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch();
                e.target.blur();
              }
            }}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 1,
              minWidth: '150px',
              flex: `0 1 ${getFlexBasis(windowWidth, 0)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          />

          <input
            id="description"
            type="text"
            value={descriptSearch}
            onChange={handleDescriptChange}
            placeholder="Enter Description"
            onFocus={() => setFocusedField('description')}
            onBlur={() => setFocusedField(null)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch();
                e.target.blur();
              }
            }}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 2,
              minWidth: '150px',
              flex: `0 1 ${getFlexBasis(windowWidth, 1)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          />

          <select
            value={manufacturer}
            onChange={handleManufacturerChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 3,
              minWidth: '150px',
              flex: `0 1 ${getFlexBasis(windowWidth, 2)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          >
            <option value="All">All Manufacturers</option>
            {manufList.map(manuf => (
              <option key={manuf.id} value={manuf.abbrname}>
                {manuf.abbrname}
              </option>
            ))}
          </select>

          <select
            value={condition}
            onChange={handleConditionChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 4,
              minWidth: '150px',
              flex: `0 1 ${getFlexBasis(windowWidth, 3)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          >
            <option value="All">All Conditions</option>
            <option value="AFTERMARKET">AFTERMARKET</option>
            <option value="CORE">CORE</option>
            <option value="NEW">NEW</option>
            <option value="REBUILT">REBUILT</option>
            <option value="USED">USED</option>
          </select>

          <button
            type="button"
            onClick={handleSearch}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '4px',
              backgroundColor: '#007BFF',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              height: '50px',
              maxWidth: '150px',
              flex: `1 1 ${getFlexBasis(windowWidth, 4)}`,
              zIndex: focusedField ? 11 : 1,
              order: windowWidth > 1025 ? 5 : 8 // Adjust order based on windowWidth
            }}
          >
            Search
          </button>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flex: '1 1 100%',
              marginLeft: '15px',
              order: 6
            }}
          >
            <label
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                cursor: 'pointer'
              }}
            >
              <input
                type="checkbox"
                checked={showCommentBox}
                onChange={e => setShowCommentBox(e.target.checked)}
                style={{
                  marginRight: '8px',
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer'
                }}
              />
              Add Comment
            </label>
          </div>

          {showCommentBox && (
            <div
              style={{
                flex: '1 1 100%',
                order: 7,
                marginLeft: '15px',
                marginRight: '15px'
              }}
            >
              <textarea
                id="comments"
                value={comments}
                onChange={handleCommentChange}
                placeholder="Enter Comments"
                onFocus={() => setFocusedField('comments')}
                onBlur={() => setFocusedField(null)}
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  position: 'relative',
                  borderRadius: '4px',
                  border: '2px solid #ccc',
                  height: '100px',
                  width: '100%',
                  zIndex: focusedField ? 11 : 1
                }}
              />
            </div>
          )}
        </div>
      </div>

      {globalErrorMessage && (
        <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>
          {globalErrorMessage}
        </div>
      )}

      {error && (
        <div
          style={{
            marginLeft: '40px',
            fontSize: '20px',
            color: 'red',
            textAlign: 'center'
          }}
        >
          <p>{error}</p>

          {error.includes('You appear to be a') && (
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <button
                type="button"
                style={{
                  marginRight: '10px',
                  padding: '8px 16px',
                  backgroundColor: '#007BFF',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  minWidth: '175px'
                }}
                onClick={() => {
                  setPartfinderUserApproved('yes');
                }}
              >
                Continue Search
              </button>
              <button
                type="button"
                style={{
                  padding: '8px 16px',
                  backgroundColor: '#007BFF',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  minWidth: '175px'
                }}
                onClick={handleRedirectToPartFinder}
              >
                Visit NetCom
              </button>
            </div>
          )}
        </div>
      )}

      {!error && processedParts.length > 0 && (
        <div>
          {processedParts[0].createdAt && (
            <p style={{ color: 'red', marginLeft: '8px', fontSize: '18px' }}>
              Part Searched for already on:{' '}
              {new Date(processedParts[0].createdAt).toLocaleDateString(
                'en-US'
              )}
            </p>
          )}
          <Table className="table-bordered table-striped">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Part Number</th>
                <th>Description</th>
                <th>Condition</th>
                <th>Price</th>
                <th>Pictures</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {processedParts.map(part => (
                <tr key={part.id}>
                  <td>
                    <button
                      onClick={() => openDetailsModal(part)}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%'
                      }}
                    >
                      {part.companyname || part.companyName || ''}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => openDetailsModal(part)}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%'
                      }}
                    >
                      {part.partnum || part.partNumber || ''}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => openDetailsModal(part)}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%'
                      }}
                    >
                      {part.descript || part.description}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => openDetailsModal(part)}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%'
                      }}
                    >
                      {part.condlong}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => openDetailsModal(part)}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%'
                      }}
                    >
                      {(part.usedprice || part.quotedPrice) === null ||
                      (part.usedprice || part.quotedPrice) === undefined ||
                      (part.usedprice || part.quotedPrice) === 0
                        ? 'Contact for price'
                        : `$${part.usedprice || part.quotedPrice}`}{' '}
                    </button>
                  </td>
                  <td
                    style={{
                      width: '120px'
                    }}
                  >
                    <button
                      onClick={() => openDetailsModal(part)}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%'
                      }}
                    >
                      <img
                        src={part.pictures[0].url}
                        alt="Thumbnail for part images"
                        style={{ width: '100px', height: '80px' }}
                        onError={e => {
                          e.target.src = '/images/image_not_available.png';
                        }}
                      />
                    </button>
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => openMessageModal(part)}
                    >
                      Send Message
                    </Button>
                    {partMessages[part.id] && (
                      <p style={{ color: 'green', marginTop: '5px' }}>
                        {partMessages[part.id]}
                      </p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {!error && processedParts.length === 0 && (
        <div
          style={{
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            margin: '20px',
            padding: '20px',
            borderRadius: '8px',
            backgroundColor: 'white'
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <h2 style={{ color: '#333' }}>NetCom Part Finding Tool</h2>
          </div>
          <div>
            <p style={{ color: '#666' }}>
              Find essential parts from the leading heavy construction dealers
              across North America with our dedicated NetCom Part Finding Tool.
              This powerful resource allows you to search and locate a wide
              variety of parts conveniently.
            </p>
            <h3 style={{ color: '#333', marginTop: '20px' }}>
              For NetCom Network Inquiries:
            </h3>
            <p style={{ color: '#666' }}>
              If you&rsquo;re interested in exploring NetCom Network options,
              please don&rsquo;t hesitate to contact us. Email us at{' '}
              <a
                href="mailto:thepartfinder@yahoo.com"
                style={{ color: '#007BFF' }}
              >
                thepartfinder@yahoo.com
              </a>{' '}
              for detailed information and personalized assistance.
            </p>
          </div>
        </div>
      )}

      <PartDetailsModal
        show={showDetailsModal}
        onHide={closeDetailsModal}
        currentPart={currentPart}
        currentPartImages={currentPartImages}
        openMessageModal={openMessageModal}
        partMessages={partMessages}
      />

      {/* Message Modal */}
      <Modal
        show={showMessageModal}
        onHide={closeMessageModal}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Send Message to {currentPart.companyname || currentPart.companyName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sendError && <Alert variant="danger">{sendError}</Alert>}
          <Form>
            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                placeholder="Enter subject"
              />
            </Form.Group>
            <Form.Group controlId="formBody">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={body}
                onChange={e => setBody(e.target.value)}
                placeholder="Enter your message"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeMessageModal}
            disabled={sending}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSendMessage}
            disabled={sending}
          >
            {sending ? 'Sending...' : 'Send Message'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PartSearch;
