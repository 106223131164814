import { useState } from 'react';

const useForgotPassword = apiBaseUrl => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const validate = () => {
    const tempErrors = {};
    if (!email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'Email is invalid';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);
    setErrors({});
    setSuccessMessage('');
    try {
      const response = await fetch(`${apiBaseUrl}/api/user/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      if (response.ok) {
        setSuccessMessage(data.message);
      } else {
        setErrors({ form: data.message || 'Error requesting password reset.' });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Forgot password error:', error);
      setErrors({ form: 'An unexpected error occurred.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    email,
    setEmail,
    errors,
    isSubmitting,
    successMessage,
    handleSubmit
  };
};

export default useForgotPassword;
