import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import useResetPassword from '../hooks/useResetPassword';

function ResetPasswordPage() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();

  // Assuming the token and email are passed as query params
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const {
    newPassword,
    setNewPassword,
    errors,
    isSubmitting,
    successMessage,
    handleSubmit,
    justReset
  } = useResetPassword(apiBaseUrl, token, email);

  React.useEffect(() => {
    if (justReset) {
      // If we've just reset and logged in user, navigate to profile
      navigate('/profile');
    }
  }, [justReset, navigate]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={10} md={9} lg={8} xl={6}>
          <Card className="p-4 mt-4 mb-5 shadow-sm">
            <Card.Body>
              <h2 className="text-center mb-4">Reset Password</h2>
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              {errors.form && <Alert variant="danger">{errors.form}</Alert>}

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="resetPasswordNewPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    isInvalid={!!errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-flex justify-content-center mt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
              <div className="text-center mt-3">
                Remembered your password? <a href="/login">Login</a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPasswordPage;
