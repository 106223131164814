import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Alert, Container } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation(); // Instantiate the hook to get location object
  const { updateTokens } = useAuth();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async e => {
    e.preventDefault();

    // Including the device information in the login request
    const deviceInfo = navigator.userAgent; // Get the user agent string

    try {
      const response = await fetch(`${baseUrl}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, deviceInfo })
      });
      const data = await response.json();
      if (response.ok) {
        // Use the updateTokens method from AuthContext to update tokens
        updateTokens(data.data.accessToken, data.data.refreshToken);

        // Check if there's a location to return to, otherwise go to profile
        const from = location.state?.from?.pathname || '/profile'; // Use React Router's useLocation to access location state
        navigate(from); // Navigate to the originally requested page or profile
      } else {
        setErrorMessage(data.message || 'Failed to login');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Login error:', error);
      setErrorMessage('Failed to login');
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>

        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" type="submit">
            Login
          </Button>
        </div>
      </Form>
      <div className="text-center my-4">
        <div className="border-top pt-3">or</div>
      </div>
      <div className="text-center mt-3">
        Need an account? <a href="/register">Create New Account</a>
      </div>
      <div className="text-center mt-3">
        <a href="/forgot-password">Forgot Password?</a>
      </div>
    </Container>
  );
}

export default LoginForm;
