import { useState } from 'react';
import { useAuth } from '../context/AuthContext';

const useResetPassword = (apiBaseUrl, token, email) => {
  const [newPassword, setNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { updateTokens } = useAuth(); // We'll use this to log user in after reset
  const [justReset, setJustReset] = useState(false);

  const validate = () => {
    const tempErrors = {};
    if (!newPassword) {
      tempErrors.newPassword = 'New password is required';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);
    setErrors({});
    setSuccessMessage('');

    try {
      const response = await fetch(`${apiBaseUrl}/api/user/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, token, newPassword })
      });
      const data = await response.json();
      if (response.ok) {
        setSuccessMessage(data.message);

        // Log the user in automatically after password reset
        const loginRes = await fetch(`${apiBaseUrl}/api/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password: newPassword })
        });
        const loginData = await loginRes.json();
        if (loginRes.ok) {
          updateTokens(loginData.data.accessToken, loginData.data.refreshToken);
          setJustReset(true);
        } else {
          // If login fails, just guide user to login page
          setSuccessMessage(
            `${data.message} Please login with your new password.`
          );
        }
      } else {
        setErrors({ form: data.message || 'Error resetting password.' });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Reset password error:', error);
      setErrors({ form: 'An unexpected error occurred.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    newPassword,
    setNewPassword,
    errors,
    isSubmitting,
    successMessage,
    handleSubmit,
    justReset
  };
};

export default useResetPassword;
