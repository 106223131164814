import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function AuthStatusListener() {
  const navigate = useNavigate();
  const { accessToken } = useAuth();

  // Paths that should not trigger a redirect to the login page
  const unprotectedPaths = [
    '/',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/login',
    '/partmatcher/guestpartsearch',
    '/partmatcher/guestbrowseparts',
    '/services/partsnetwork',
    '/services/partseller',
    '/services/storefront',
    '/services/testimonials',
    '/forsale/guestengines',
    '/forsale/guestmachines',
    '/forsale/guestattachments',
    '/forsale/guesttransmissions',
    '/forsale/guestotheritems',
    '/galleries/thompsonmachinery',
    '/galleries/yellowstonevalley',
    '/galleries/wellertractor',
    '/galleries/boydandsons',
    '/galleries/ironking',
    '/galleries/billmiller',
    '/galleries/heavyquip',
    '/galleries/altorfer',
    '/galleries/carter',
    '/galleries/milton',
    '/contact',
    '/legal/privacypolicy',
    '/legal/termsofuse'
  ];

  useEffect(() => {
    const currentPath = window.location.pathname;

    const isUnprotected = unprotectedPaths.some(path =>
      new RegExp(`^${path}(/|$)`).test(currentPath)
    );

    if (!accessToken && !isUnprotected) {
      navigate('/login');
    }
  }, [accessToken, navigate]);

  return null; // This component doesn't render anything
}

export default AuthStatusListener;
